<script
    lang="ts"
    setup
>
    import type { AppDropdownProps } from '~/ts/types/app'
    import type { FormSelectOption } from '~/ts/types/form'

    type Props = {
        modelValue: any
        name: string
        options: FormSelectOption[]
        optionsPreferred?: FormSelectOption[]
        error?: string
        multiple?: boolean
        multipleTextVariant?: boolean
        grouped?: boolean
        label?: string
        placeholder?: string
        disabled?: boolean
        loading?: boolean
        enableVirtualList?: boolean
        searchable?: boolean
        searchInitialValue?: string
        searchByExtraValue?: string
        searchPlaceholderTransKey?: string
        listItemHeight?: number
        withoutErrorText?: boolean
        dropdownArgs?: AppDropdownProps
        containerClass?: string
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
        (event: 'update:error', value: string): void
        (event: 'search:keydown-enter', DOMEvent: Event, search: string, searchedOption?: FormSelectOption): void
    }

    enum ListItemTypeEnum {
        Normal = 'normal',
        Preferred = 'preferred',
        Group = 'group',
        Divider = 'divider'
    }

    type ListItem = {
        type: ListItemTypeEnum
        data?: FormSelectOption | string
    }

    const props = withDefaults(defineProps<Props>(), {
        error: '',
        optionsPreferred: undefined,
        multiple: false,
        multipleTextVariant: false,
        grouped: false,
        label: undefined,
        placeholder: undefined,
        disabled: false,
        loading: false,
        enableVirtualList: false,
        dropdownArgs: undefined,
        searchable: false,
        searchInitialValue: '',
        searchByExtraValue: undefined,
        searchPlaceholderTransKey: 'search',
        listItemHeight: undefined,
        withoutErrorText: undefined,
        containerClass: undefined
    })
    const emit = defineEmits<Emit>()

    const DEFAULT_LIST_ITEM_HEIGHT = 36
    const LIST_ITEM_GAP = 4
    const LIST_GROUP_HEIGHT = 21
    const LIST_DIVIDER_HEIGHT = 1
    const DROPDOWN_MAX_HEIGHT = 360
    const DROPDOWN_PADDING = 16
    const SEARCH_FIELD_HEIGHT_AND_INDENT = 38
    const DEFAULT_VIRTUAL_LIST_MAX_CONTAINER_HEIGHT = DROPDOWN_MAX_HEIGHT - DROPDOWN_PADDING

    const inputRef = ref<HTMLInputElement>()

    const listItemHeight = computed<number>(() => {
        return props.listItemHeight ?? DEFAULT_LIST_ITEM_HEIGHT
    })

    const virtualListMaxContainerHeight = computed<number>(() => {
        if (!props.enableVirtualList) {
            return 0
        }

        return DEFAULT_VIRTUAL_LIST_MAX_CONTAINER_HEIGHT
            - (props.searchable ? SEARCH_FIELD_HEIGHT_AND_INDENT : 0)
    })

    const searchRef = ref<ReturnType<typeof defineComponent>>()

    const slots = useSlots()

    const showFallbackOption = computed<boolean>(() => {
        return !!slots['fallback-option']
            && !!search.value
            && !searchedOption.value
    })

    const searchedOption = computed<FormSelectOption | undefined>(() => {
        if (!search.value) {
            return
        }

        return optionsFiltered.value.find(v => (v.text === search.value))
    })

    const selectedOption = computed<FormSelectOption>(() => {
        return props.options.find(v => (v.value === props.modelValue))
    })

    const selectedMultipleOptions = computed<FormSelectOption[]>(() => {
        return props.options.filter(v => props.modelValue.includes(v.value))
    })

    const isMultipleDisplay = computed<boolean>(() => props.multiple && !props.multipleTextVariant)

    const inputValue = computed<string>(() => {
        if (isEmpty(props.modelValue)) {
            return ''
        }

        if (props.multiple) {
            return selectedMultipleOptions.value.map(v => v.text).join(', ')
        }

        return selectedOption.value?.text || ''
    })

    watch(inputValue, () => {
        inputRef.value?.dispatchEvent(new Event('input', { bubbles: true }))
    })

    const search = ref<string>(props.searchInitialValue)

    const optionsContainerRef = ref<HTMLDivElement>()
    const virtualListRef = ref<ReturnType<typeof defineComponent>>()

    const optionsFiltered = computed(() => {
        const searchValue = search.value.toLowerCase()

        if (!searchValue) {
            return props.options
        }

        if (props.searchByExtraValue) {
            return props.options
                .filter(v => String(v._extra[props.searchByExtraValue]).toLowerCase().includes(searchValue))
        }

        return props.options
            .filter(v => String(v.text).toLowerCase().includes(searchValue))
    })

    const { t } = useLang()

    const optionsGrouped = computed(() => {
        if (!props.grouped) {
            return {
                _default: optionsFiltered.value
            }
        }

        const optionsGrouped = {}
        const ungroupedOptions = []

        for (const option of optionsFiltered.value) {
            if (!option.group) {
                ungroupedOptions.push(option)

                continue
            }

            if (!optionsGrouped[option.group]) {
                optionsGrouped[option.group] = []
            }

            optionsGrouped[option.group].push(option)
        }

        if (ungroupedOptions.length) {
            optionsGrouped[t('ungrouped')] = ungroupedOptions
        }

        return optionsGrouped
    })

    const listItems = computed<ListItem[]>(() => {
        const list: ListItem[] = []

        if (props.optionsPreferred && !search.value) {
            for (let i = 0, length = props.optionsPreferred.length; i < length; i++) {
                list.push({
                    type: ListItemTypeEnum.Preferred,
                    data: props.optionsPreferred[i]
                })
            }

            list.push({ type: ListItemTypeEnum.Divider })
        }

        if (props.grouped) {
            for (const group in optionsGrouped.value) {
                list.push({
                    type: ListItemTypeEnum.Group,
                    data: group
                })

                const groupOptions = optionsGrouped.value[group]

                for (let i = 0, length = groupOptions.length; i < length; i++) {
                    list.push({
                        type: ListItemTypeEnum.Normal,
                        data: groupOptions[i]
                    })
                }
            }
        } else {
            for (let i = 0, length = optionsFiltered.value.length; i < length; i++) {
                list.push({
                    type: ListItemTypeEnum.Normal,
                    data: optionsFiltered.value[i]
                })
            }
        }

        return list
    })

    const selectedListItemIndex = computed<number>(() => {
        const selectedValue = props.multiple
            ? props.modelValue[0]
            : props.modelValue

        if (!selectedValue) {
            return -1
        }

        return listItems.value.findIndex((item) => {
            if (![ ListItemTypeEnum.Normal, ListItemTypeEnum.Preferred ].includes(item.type)) {
                return false
            }

            return (item.data as FormSelectOption).value === selectedValue
        })
    })

    const hoveredOption = ref()

    const onOpened = () => {
        if (!optionsContainerRef.value || !virtualListRef.value) {
            return
        }

        if (searchRef.value) {
            searchRef.value.$refs.inputRef.focus()
        }

        if (optionsContainerRef.value.children.length) {
            scrollToSelectedItem()
        }
    }

    const scrollToSelectedItem = (): void => {
        if (props.enableVirtualList) {
            if (selectedListItemIndex.value !== -1) {
                virtualListRef.value.scrollTo(selectedListItemIndex.value)
            }

            return
        }

        const selectedOptionEl = optionsContainerRef.value.querySelector('[data-active]')

        if (selectedOptionEl) {
            nextTick(() => {
                selectedOptionEl.scrollIntoView({
                    block: 'nearest'
                })
            })
        }
    }

    const selectOption = (item: FormSelectOption, done?: Function) => {
        const formResetting = !item

        if (formResetting) {
            emit('update:modelValue', props.multiple ? [] : '')

            return
        }

        if (props.disabled || item.disabled) {
            return
        }

        if (props.error) {
            emit('update:error', '')
        }

        if (props.multiple) {
            if (props.modelValue.includes(item.value)) {
                emit('update:modelValue', props.modelValue.filter(v => v !== item.value))
            } else {
                emit('update:modelValue', [ ...props.modelValue, item.value ])
            }

            return
        }

        emit('update:modelValue', item.value)

        done?.()
    }

    const getOptionArgs = (item) => {
        const args = {
            disabled: item.disabled,
            active: false
        }

        if (props.multiple) {
            if (props.modelValue.includes(item.value)) {
                args.active = true
            }
        } else if (item.value === props.modelValue) {
            args.active = true
        }

        return args
    }

    const clearSearch = () => (search.value = props.searchInitialValue)

    const getItemHeight = (index: number): number => {
        const listItem: ListItem = listItems.value[index]

        if (!listItem) {
            return listItemHeight.value
        }

        switch (listItem.type) {
            case ListItemTypeEnum.Group:
                return LIST_GROUP_HEIGHT
            case ListItemTypeEnum.Divider:
                return LIST_DIVIDER_HEIGHT
            default:
                return listItemHeight.value
        }
    }

    defineExpose({
        selectOption,
        clearSearch
    })
</script>

<template>
    <AppDropdown
        offset-y="-6"
        max-height="360"
        v-bind="props.dropdownArgs"
        :disable-scroll="props.enableVirtualList"
        class="!select-none"
        @opened="onOpened()"
    >
        <template #activator="activatorProps">
            <slot
                name="field"
                v-bind="activatorProps"
                :error-message="props.error"
            >
                <AppFormFieldContainer
                    :disabled="props.disabled"
                    :loading="props.loading"
                    :error-message="props.error"
                    :without-error-text="props.withoutErrorText"
                    :class="[ '!cursor-pointer', props.containerClass ]"
                    :content-class="isMultipleDisplay ? 'overflow-hidden' : undefined"
                >
                    <template #default="{ inputClass, labelClass }">
                        <slot
                            name="activator-input"
                            :input-value="inputValue"
                            :input-class="inputClass"
                            :activator-props="activatorProps"
                        >
                            <template v-if="!isMultipleDisplay">
                                <input
                                    key="input"
                                    ref="inputRef"
                                    :value="inputValue"
                                    :name="props.name"
                                    :disabled="props.disabled"
                                    :placeholder="props.placeholder || ' '"
                                    :class="inputClass"
                                    readonly
                                    class="pointer-events-none"
                                    autocomplete="off"
                                    @focusin="activatorProps.toggle"
                                />
                            </template>

                            <span
                                v-if="props.label"
                                key="label"
                                :class="[
                                    labelClass,
                                    { '!text-[12px]': (isMultipleDisplay && selectedMultipleOptions.length) }
                                ]"
                            >
                                {{ props.label }}
                            </span>

                            <span
                                v-if="isMultipleDisplay"
                                key="multiple-select"
                                :class="[
                                    'relative overflow-auto max-h-[108px] flex flex-wrap gap-1 w-full',
                                    { 'mt-1' : (props.label && selectedMultipleOptions.length) }
                                ]"
                            >
                                <AppChip
                                    v-for="option in selectedMultipleOptions"
                                    :key="option.value"
                                    tag="span"
                                    class="overflow-hidden"
                                    content-class="overflow-hidden"
                                    clearable
                                    @clear="selectOption(option)"
                                >
                                    <span class="truncate">
                                        {{ option.text }}
                                    </span>
                                </AppChip>

                                <input
                                    ref="inputRef"
                                    key="input"
                                    :value="inputValue"
                                    :name="props.name"
                                    :disabled="props.disabled"
                                    :placeholder="props.placeholder || ' '"
                                    class="absolute w-0 h-0 overflow-hidden opacity-0"
                                    readonly
                                    autocomplete="off"
                                    @focusin="activatorProps.toggle"
                                />
                            </span>
                        </slot>
                    </template>

                    <template #suffix>
                        <AppIconChevronDown
                            size="20"
                            :rotate="activatorProps.active"
                        />
                    </template>
                </AppFormFieldContainer>
            </slot>
        </template>

        <template #default="{ close }">
            <AppFormFieldSearch
                v-if="props.searchable"
                key="search"
                ref="searchRef"
                v-model.trim="search"
                small
                name="search"
                :placeholder="$t(searchPlaceholderTransKey)"
                :class="[ '!w-full', { '!mb-2': optionsFiltered.length } ]"
                @keydown.enter="emit('search:keydown-enter', $event, search, searchedOption)"
            />

            <div
                ref="optionsContainerRef"
                key="options"
                class="flex flex-col gap-1 min-w-px select-none"
            >
                <slot
                    name="options-content"
                    :options="props.options"
                    :select-option="option => selectOption(option, close)"
                >
                    <AppVirtualList
                        ref="virtualListRef"
                        v-slot="{ itemAttrs }"
                        :disabled="!props.enableVirtualList"
                        :items="listItems"
                        :item-indent="LIST_ITEM_GAP"
                        :total-height-minus="LIST_ITEM_GAP"
                        :max-container-height="virtualListMaxContainerHeight"
                        :item-height-manually="getItemHeight"
                        :render-ahead="3"
                        wrapper-class="gap-1"
                    >
                        <div
                            v-if="itemAttrs.item.type === ListItemTypeEnum.Group"
                            :key="itemAttrs.item.data"
                            class="
                                p-[4px_0_4px_8px]
                                text-[10px]
                                text-[#a7a7a7]
                                uppercase
                                font-medium
                                leading-[130%]
                            "
                        >
                            {{ itemAttrs.item.data }}
                        </div>

                        <AppDivider
                            v-else-if="itemAttrs.item.type === ListItemTypeEnum.Divider"
                            :key="itemAttrs.item.type + itemAttrs.index"
                        />

                        <AppDropdownItem
                            v-else
                            :key="itemAttrs.item.data.text + itemAttrs.index"
                            v-bind="getOptionArgs(itemAttrs.item.data)"
                            @mouseenter="hoveredOption = itemAttrs.item.data.value"
                            @mouseleave="hoveredOption = null"
                            @click="selectOption(itemAttrs.item.data, close)"
                        >
                            <slot
                                name="option"
                                :option="itemAttrs.item.data"
                                :option-type="itemAttrs.item.type"
                            >
                                <!-- eslint-disable max-len -->
                                <AppIconCheckBold
                                    v-if="props.multiple"
                                    key="icon"
                                    size="16"
                                    class="pointer-events-none mr-2"
                                    :color="props.modelValue.includes(itemAttrs.item.data.value) ? '#000' : 'transparent'"
                                />
                                <!-- eslint-enable max-len -->

                                <slot
                                    name="option-text"
                                    :option="itemAttrs.item.data"
                                    :hovered="hoveredOption === itemAttrs.item.data.value"
                                >
                                    <span class="truncate">
                                        {{ itemAttrs.item.data.text }}
                                    </span>
                                </slot>
                            </slot>
                        </AppDropdownItem>
                    </AppVirtualList>

                    <slot
                        v-if="showFallbackOption"
                        name="fallback-option"
                        :search="search"
                        :clear-search="clearSearch"
                    />
                </slot>
            </div>
        </template>
    </AppDropdown>
</template>
